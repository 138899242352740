import { useEffect, useState } from 'react';
import { MdOutlineSearch, MdClose } from 'react-icons/md';

import useSftpHook from 'views/SFTP/SFTP.hook';

function SearchInput() {
  const { search, handleSearch } = useSftpHook();
  const [value, setValue] = useState('');

  const onSearch = (event) => {
    if (event.key === 'Enter') {
      handleSearch(value.trim()); // trigger search only on Enter key
    }
  };

  useEffect(() => {
    if (!search) setValue('');
  }, [search]);

  return (
    <div className="mt-8 relative flex items-center justify-center p-4">
      <input
        value={value}
        onChange={({ target }) => setValue(target.value)} // update state but don't trigger search yet
        onKeyDown={onSearch}
        placeholder="Search loan package by loan id"
        className="border border-secondary rounded-xl shadow-lg w-full p-4"
      />
      <div className="absolute right-10">
        {search ? (
          <MdClose
            className="cursor-pointer"
            color="#69d393"
            onClick={() => {
              setValue('');
              handleSearch('');
            }}
            size={25}
          />
        ) : (
          <MdOutlineSearch color="#69d393" size={25} />
        )}
      </div>
    </div>
  );
}

export default SearchInput;
